import { ACTION, LABEL, VALUE } from './constants';
import { CATEGORIES, GAEventType } from './types';

function pushNativeGaEvent(data: GAEventType) {
  window.dataLayer = window?.dataLayer || [];
  window.dataLayer.push(data);
}

function pushGAEvent(eventLabel = ''): void {
  if (!eventLabel || typeof window === 'undefined') {
    return;
  }

  const data = {
    'data-event-action': ACTION,
    'data-event-category': CATEGORIES.SPONSORSHIP,
    'data-event-label': `${LABEL} ${eventLabel}`,
    'data-event-value': VALUE,
    event: 'eventTracker',
  };
  pushNativeGaEvent(data);
}

const initDigitalDataNonProdEnv = (): void => {
  const nonProd = process.env.ROKT_SANDBOX_WIDGET_MODE;

  if (nonProd === 'false') return;

  window.digitalData = {
    page: {
      pageInfo: {
        environment: 'Dev',
      },
    },
  };
};

export { initDigitalDataNonProdEnv, pushGAEvent, pushNativeGaEvent };
