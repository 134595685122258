enum OutboundEvents {
  PARKWHIZ_SDK_IFRAME_SHOULD_INITIALIZE = 'PARKWHIZ_SDK_IFRAME_SHOULD_INITIALIZE',
  PARKWHIZ_SDK_IFRAME_WAS_INITIALIZED = 'PARKWHIZ_SDK_IFRAME_WAS_INITIALIZED',
  PARKWHIZ_SDK_IFRAME_LOADED = 'PARKWHIZ_SDK_IFRAME_LOADED',
  PARKWHIZ_SDK_ON_INIT_ERROR = 'PARKWHIZ_SDK_ON_INIT_ERROR',
  PARKWHIZ_SDK_ON_FAILURE = 'PARKWHIZ_SDK_ON_FAILURE',
  PARKWHIZ_SDK_ON_UPDATE_ITEM_SUBSCRIPTION = 'PARKWHIZ_SDK_ON_UPDATE_ITEM_SUBSCRIPTION',
  PARKWHIZ_SDK_ON_UPDATE_ITEM = 'PARKWHIZ_SDK_ON_UPDATE_ITEM',
  PARKWHIZ_SDK_ON_UX_VARIANT = 'PARKWHIZ_SDK_ON_UX_VARIANT',
  PARKWHIZ_SDK_ON_DELIVERY_METHOD_CHANGE = 'PARKWHIZ_SDK_ON_DELIVERY_METHOD_CHANGE',
  PARKWHIZ_SDK_ON_DELIVERY_COUNTRY_CHANGE = 'PARKWHIZ_SDK_ON_DELIVERY_COUNTRY_CHANGE',
  PARKWHIZ_SDK_ON_HIDE_MESSAGE = 'PARKWHIZ_SDK_ON_HIDE_MESSAGE',
  PARKWHIZ_SDK_REGISTER_CALLBACKS = 'PARKWHIZ_SDK_REGISTER_CALLBACKS',
  PARKWHIZ_SDK_ON_UPDATE_CART_ITEM = 'PARKWHIZ_SDK_ON_UPDATE_CART_ITEM',
  FANATICS_DELIVERY_DETAILS_SENT = 'PARKWHIZ_SDK_ON_FANATICS_DELIVERY_DETAILS_SENT',
  PARKWHIZ_SDK_ON_READ_MORE = 'PARKWHIZ_SDK_ON_READ_MORE',
}

enum InboundEvents {
  PARKWHIZ_SDK_INIT = 'PARKWHIZ_SDK_INIT',
  PARKWHIZ_SDK_INITIALIZED = 'PARKWHIZ_SDK_INITIALIZED',
  PARKWHIZ_SDK_IFRAME_RESIZED = 'PARKWHIZ_SDK_IFRAME_RESIZED',
  PARKWHIZ_SDK_IFRAME_LOADED = 'PARKWHIZ_SDK_IFRAME_LOADED',
  PARKWHIZ_SDK_ON_INIT_ERROR = 'PARKWHIZ_SDK_ON_INIT_ERROR',
  PARKWHIZ_SDK_ON_FAILURE = 'PARKWHIZ_SDK_ON_FAILURE',
  PARKWHIZ_SDK_ON_UPDATE_ITEM_SUBSCRIPTION = 'PARKWHIZ_SDK_ON_UPDATE_ITEM_SUBSCRIPTION',
  PARKWHIZ_SDK_ON_UPDATE_ITEM = 'PARKWHIZ_SDK_ON_UPDATE_ITEM',
  PARKWHIZ_SDK_ON_UX_VARIANT = 'PARKWHIZ_SDK_ON_UX_VARIANT',
  PARKWHIZ_SDK_ON_DELIVERY_METHOD_CHANGE = 'PARKWHIZ_SDK_ON_DELIVERY_METHOD_CHANGE',
  PARKWHIZ_SDK_ON_DELIVERY_COUNTRY_CHANGE = 'PARKWHIZ_SDK_ON_DELIVERY_COUNTRY_CHANGE',
  PARKWHIZ_SDK_ON_HIDE_MESSAGE = 'PARKWHIZ_SDK_ON_HIDE_MESSAGE',
  PARKWHIZ_SDK_REGISTER_CALLBACKS = 'PARKWHIZ_SDK_REGISTER_CALLBACKS',
  PARKWHIZ_SDK_ON_UPDATE_CART_ITEM = 'PARKWHIZ_SDK_ON_UPDATE_CART_ITEM',
  FANATICS_DELIVERY_DETAILS_SENT = 'PARKWHIZ_SDK_ON_FANATICS_DELIVERY_DETAILS_SENT',
  PARKWHIZ_SDK_ON_READ_MORE = 'PARKWHIZ_SDK_ON_READ_MORE',
}

enum CallbackTypes {
  ON_FAILURE = 'onFailure',
  ON_UPDATE_ITEM = 'onUpdateItem',
  ON_UPDATE_ITEM_SUBSCRIPTION = 'onUpdateItemSubscription',
  ON_INIT_ERROR = 'onInitError',
  ON_DELIVERY_DATA_UPDATED = 'onFanaticsDeliveryUpdate',
  ON_READ_MORE = 'onReadMoreCallback',
}

export type EventMessage = {
  event: OutboundEvents | InboundEvents;
  message: any;
  destination?: string;
};

export type Margins = {
  pageIdentifier: string;
  components: string[];
  className: string;
};

export type Callbacks = {
  placementEvent: string;
  placementCallback: string;
};

export const PLACEMENTS_CALLBACKS: Callbacks[] = [
  { placementEvent: 'UPDATE_CART_ITEM_MESSAGE', placementCallback: 'onUpdateItem' },
  { placementEvent: 'DELIVERY_DETAILS_SENT', placementCallback: 'onFanaticsDeliveryUpdate' },
  { placementEvent: 'UPSELL_CREATIVE_SENT', placementCallback: 'onReadMoreCallback' },
];

export { CallbackTypes, InboundEvents, OutboundEvents };
