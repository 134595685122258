export type GAEventType = {
  event: string;
  'data-event-category': CATEGORIES;
  'data-event-action'?: string;
  'data-event-label': string;
  'data-event-value'?: number;
};

export enum CATEGORIES {
  SPONSORSHIP = 'Sponsorship Product',
}
