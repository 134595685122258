import {
  DEFAULT_CONTAINER_ID,
  IFRAME_ID,
  IFRAME_STYLE,
  S3_PUBLIC_DOMAIN_NAME,
} from '@comm_service/constants';
import Errors from '@comm_service/constants/errors';
import { SDKProps } from '@rokt/types/sdk';
import {
  CallbackTypes,
  EventMessage,
  InboundEvents,
  OutboundEvents,
} from '@shared/types/commService';
import { EMPTY_FUNCTION } from '@shared/utils';

export class CommunicationService {
  private _onLoaded: (message: any) => void;
  private _onInitialized: (value: any) => void;
  private _GAHandler: (variantName: string) => void;
  private _onInitError: (errorMessage: string) => void;
  public _targetIframe: Window;
  public _targetUrl: string;
  public iframe: HTMLIFrameElement = document.createElement('iframe');
  private observer: ResizeObserver | null = null;
  private static readonly MARGIN_COMPENSATION: number = 16;
  public handleRegisterCallbacks: (...args: any[]) => any;
  public handleExecuteCallback: (...args: any[]) => any;

  constructor() {
    this._onLoaded = EMPTY_FUNCTION;
    this._onInitialized = EMPTY_FUNCTION;
    this._GAHandler = EMPTY_FUNCTION;
    this._onInitError = EMPTY_FUNCTION;
    this.routeEventMessage = this.routeEventMessage.bind(this);
    this.registerMessageListener();
    this._targetIframe = window.parent;
    this._targetUrl = '';
    this.handleRegisterCallbacks = EMPTY_FUNCTION;
    this.handleExecuteCallback = EMPTY_FUNCTION;
  }

  async render(
    callback: (value: unknown) => void,
    GAHandler: (varianName: string) => void
  ): Promise<void> {
    this._onLoaded = callback;
    this.createIframe();
    this._GAHandler = GAHandler;
  }

  init(props: SDKProps | null, callback: (value: unknown) => void): void {
    this._onInitialized = callback;
    if (!this.getIframeContainer()) throw new Error(Errors.COMPONENT_INSTANCE_NOT_CREATED);

    this.routeEventMessage({
      data: {
        event: InboundEvents.PARKWHIZ_SDK_INIT,
        message: props,
      },
    });
  }

  createIframe(): void {
    this.iframe = document.createElement('iframe');
    this.iframe.id = IFRAME_ID;
    this.iframe.setAttribute('style', IFRAME_STYLE);
    this.iframe.src = `${this.getInstanceUrl()}?targetOrigin=${encodeURIComponent(
      `${window.parent.location.protocol}//${window.parent.location.host}`
    )}`;

    const iframeContainer = document.getElementById(DEFAULT_CONTAINER_ID);

    if (iframeContainer) {
      iframeContainer.appendChild(this.iframe);
      this._targetIframe = this.iframe.contentWindow ?? window.parent;
      this._targetUrl = this.getInstanceUrl();
    } else throw new Error(Errors.MISSING_CONTAINER);
  }

  resizeIframe(newHeight: number): void {
    this.iframe.height = `${newHeight + CommunicationService.MARGIN_COMPENSATION}px`;
  }

  notifyResize(entries: any) {
    for (const entry in entries) {
      const newHeight = entries[entry].contentRect.height;
      this.postIframeMessage({
        event: InboundEvents.PARKWHIZ_SDK_IFRAME_RESIZED,
        message: newHeight,
      });
    }
  }

  registerOnInitError(callback: any) {
    this._onInitError = callback;
  }

  setTargetOrigin(origin: string): void {
    if (!this._targetUrl) this._targetUrl = origin;
  }

  getIframeContainer(): boolean {
    const element = document.getElementById(DEFAULT_CONTAINER_ID);
    return element !== null;
  }

  getInstanceUrl(): string {
    if (!process.env.S3_PUBLIC_DOMAIN_NAME) {
      return `${S3_PUBLIC_DOMAIN_NAME}/${process.env.ENV_PATH}/index.html`;
    }

    return process.env.S3_PUBLIC_DOMAIN_NAME + process.env.S3_BUCKET;
  }

  registerMessageListener(): void {
    window.addEventListener('message', this.routeEventMessage);
  }

  deliveryMethodChange(message: any) {
    if (window?.SponsParkwhizWidget?.deliveryMethodChange)
      window.SponsParkwhizWidget.deliveryMethodChange(message);
  }

  deliveryCountryChange(message: any): void {
    if (window?.SponsParkwhizWidget?.deliveryCountryChange)
      window.SponsParkwhizWidget.deliveryCountryChange(message);
  }

  hideMessage(): void {
    if (window?.SponsParkwhizWidget?.hideMessage) window.SponsParkwhizWidget.hideMessage();
  }

  updateCartItem(message: any) {
    if (window?.SponsParkwhizWidget?.updateCartItem)
      window.SponsParkwhizWidget.updateCartItem(message);
  }

  dispatchInitError(err: any) {
    this.routeEventMessage({
      data: {
        event: InboundEvents.PARKWHIZ_SDK_ON_INIT_ERROR,
        message: err.toString(),
      },
    });
  }

  handleRoktShouldInitialize(message: any): void | boolean {
    if (window?.SponsParkwhizWidget?.init)
      window.SponsParkwhizWidget.init(message).catch((err: Error) => {
        this.dispatchInitError(err);
      });
    else return false;
  }

  /**
   * Receives an event message and routes it to the appropriate handler
   * @param eventMessage - The event message emitted by the iframe or window
   */

  routeEventMessage(eventMessage: any) {
    const {
      data: { event, message, destination },
    } = eventMessage;

    destination === 'iframe'
      ? this.iframeMessagerHandler({ event, message })
      : this.windowMessagerHandler({ event, message });
  }

  /**
   * Receives an inbound event message and posts an outbound message to the iframe
   * @param data - The event message and the type of event emitted
   */
  windowMessagerHandler(data: EventMessage) {
    const { event, message } = data;

    switch (event) {
      case InboundEvents.PARKWHIZ_SDK_IFRAME_RESIZED:
        this.resizeIframe(message);
        break;

      case InboundEvents.PARKWHIZ_SDK_IFRAME_LOADED:
        this.observer = new window.ResizeObserver(this.notifyResize.bind(this));
        this.observer.observe(document.body);
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_IFRAME_LOADED,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_INIT:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_IFRAME_SHOULD_INITIALIZE,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_INITIALIZED:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_IFRAME_WAS_INITIALIZED,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_REGISTER_CALLBACKS:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_REGISTER_CALLBACKS,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_UPDATE_ITEM:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_UPDATE_ITEM,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_UPDATE_ITEM_SUBSCRIPTION:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_UPDATE_ITEM_SUBSCRIPTION,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_FAILURE:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_FAILURE,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_UX_VARIANT:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_UX_VARIANT,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_INIT_ERROR:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_INIT_ERROR,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_DELIVERY_METHOD_CHANGE:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_DELIVERY_METHOD_CHANGE,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_DELIVERY_COUNTRY_CHANGE:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_DELIVERY_COUNTRY_CHANGE,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_HIDE_MESSAGE:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_HIDE_MESSAGE,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.PARKWHIZ_SDK_ON_UPDATE_CART_ITEM:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_UPDATE_CART_ITEM,
          message,
          destination: 'iframe',
        });
        break;

      case InboundEvents.FANATICS_DELIVERY_DETAILS_SENT:
        this.postIframeMessage({
          event: OutboundEvents.FANATICS_DELIVERY_DETAILS_SENT,
          message,
          destination: 'iframe',
        });
        break;
      case InboundEvents.PARKWHIZ_SDK_ON_READ_MORE:
        this.postIframeMessage({
          event: OutboundEvents.PARKWHIZ_SDK_ON_READ_MORE,
          message,
          destination: 'iframe',
        });
        break;
    }
  }

  /**
   * Receives the messages that are destined for the iframe
   * @param data - The event message and the type of event emitted
   */
  iframeMessagerHandler(data: EventMessage) {
    const { event, message } = data;

    switch (event) {
      case OutboundEvents.PARKWHIZ_SDK_IFRAME_SHOULD_INITIALIZE:
        this.handleRoktShouldInitialize(message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_IFRAME_WAS_INITIALIZED:
        this._onInitialized(message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_IFRAME_LOADED:
        this._onLoaded(message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_REGISTER_CALLBACKS:
        try {
          this.handleRegisterCallbacks();
        } catch (err: any) {
          this.dispatchInitError(err);
        }
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_UPDATE_ITEM:
        this.handleExecuteCallback(CallbackTypes.ON_UPDATE_ITEM, message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_UPDATE_ITEM_SUBSCRIPTION:
        this.handleExecuteCallback(CallbackTypes.ON_UPDATE_ITEM_SUBSCRIPTION, message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_FAILURE:
        this.handleExecuteCallback(CallbackTypes.ON_FAILURE, message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_UX_VARIANT:
        this._GAHandler(message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_INIT_ERROR:
        this._onInitError(message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_DELIVERY_METHOD_CHANGE:
        this.deliveryMethodChange(message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_DELIVERY_COUNTRY_CHANGE:
        this.deliveryCountryChange(message);
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_HIDE_MESSAGE:
        this.hideMessage();
        break;

      case OutboundEvents.PARKWHIZ_SDK_ON_UPDATE_CART_ITEM:
        this.updateCartItem(message);
        break;

      case OutboundEvents.FANATICS_DELIVERY_DETAILS_SENT:
        this.handleExecuteCallback(CallbackTypes.ON_DELIVERY_DATA_UPDATED, message);
        break;
      case OutboundEvents.PARKWHIZ_SDK_ON_READ_MORE:
        this.handleExecuteCallback(CallbackTypes.ON_READ_MORE, message);
        break;
    }
  }

  /**
   * Posts a message to the iframe
   * @param data - The post message to be sent to the iframe
   */
  postIframeMessage(data: EventMessage) {
    this._targetIframe.postMessage(data, this._targetUrl);
  }
}

export default new CommunicationService();
