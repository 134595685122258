const findGetParameter = (serchString: string, parameterName: string): string => {
  let result = '',
    tmp = [];
  serchString
    .substring(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};

const EMPTY_FUNCTION = () => {};

const isEmptyFunction = (fn: (...args: any[]) => any) => fn === EMPTY_FUNCTION;

const parseToObject = (rawObj: string | undefined) => {
  if (rawObj) {
    let stringOutput = '';

    rawObj.split('&').forEach((keyValuePair) => {
      const nameAndValue = keyValuePair.split('=');
      stringOutput += `"${nameAndValue[0]}":"${nameAndValue[1]}",`;
    });

    return JSON.parse('{' + stringOutput.slice(0, -1) + '}');
  }
};

const getCookie = (name: string): string | undefined => {
  const escapedName = name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1');
  const cookieValueRegex = new RegExp('(?:^|; )' + escapedName + '=([^;]*)');

  const cookieValueMatch = RegExp(cookieValueRegex).exec(document.cookie);

  return cookieValueMatch ? decodeURIComponent(cookieValueMatch[1]) : undefined;
};

export { EMPTY_FUNCTION, findGetParameter, getCookie, isEmptyFunction, parseToObject };
