import { callbackRegistry } from '@shared/types';
import { CallbackTypes } from '@shared/types/commService';
import { EMPTY_FUNCTION, isEmptyFunction } from '@shared/utils';

export class RoktProxy {
  cart;
  protected callbacksRegistry: callbackRegistry = {
    onFailure: EMPTY_FUNCTION,
    onUpdateItem: EMPTY_FUNCTION,
    onUpdateItemSubscription: EMPTY_FUNCTION,
    onFanaticsDeliveryUpdate: EMPTY_FUNCTION,
    onReadMoreCallback: EMPTY_FUNCTION,
  };
  onFailure: (...args: any[]) => any;
  onUpdateItemSubscription: (...args: any[]) => any;
  onFanaticsDeliveryUpdate: (...args: any[]) => any;
  onReadMoreCallback: (...args: any[]) => any;
  private _registerCallbacksMessage: any;

  constructor() {
    this.cart = {
      onUpdateItem: this._onUpdateItem.bind(this),
    };
    this.onFailure = this._onFailure.bind(this);
    this._registerCallbacksMessage = null;
    this.onUpdateItemSubscription = this._onUpdateItemSubscription.bind(this);
    this.onFanaticsDeliveryUpdate = this._onFanaticsDeliveryUpdated.bind(this);
    this.onReadMoreCallback = this._onReadMoreCallback.bind(this);
  }

  private _registerCallback(method: any, callback: (...args: any[]) => any): void {
    this._setValue(method, callback);

    if (this._isValidRegistry()) {
      this._registerCallbacksMessage();
    }
  }

  private _isValidRegistry(): boolean {
    return !!(
      (!isEmptyFunction(this.getValue(CallbackTypes.ON_UPDATE_ITEM)) ||
        !isEmptyFunction(this.getValue(CallbackTypes.ON_UPDATE_ITEM_SUBSCRIPTION))) &&
      !isEmptyFunction(this.getValue(CallbackTypes.ON_FAILURE))
    );
  }

  getValue(methodName: string): (...args: any[]) => any | null {
    return methodName in this.callbacksRegistry ? this.callbacksRegistry[methodName] : null;
  }

  private _setValue(methodName: string, value: (...args: any[]) => any): void {
    this.callbacksRegistry[methodName] = value;
  }

  private _onUpdateItemSubscription(callback: (...args: any[]) => any): void {
    this._registerCallback(CallbackTypes.ON_UPDATE_ITEM_SUBSCRIPTION, callback);
  }

  private _onUpdateItem(callback: (...args: any[]) => any): void {
    this._registerCallback(CallbackTypes.ON_UPDATE_ITEM, callback);
  }

  private _onFailure(callback: (...args: any[]) => any): void {
    this._registerCallback(CallbackTypes.ON_FAILURE, callback);
  }

  private _onFanaticsDeliveryUpdated(callback: (...args: any[]) => any): void {
    this._registerCallback(CallbackTypes.ON_DELIVERY_DATA_UPDATED, callback);
  }

  private _onReadMoreCallback(callback: (...args: any[]) => any): void {
    this._registerCallback(CallbackTypes.ON_READ_MORE, callback);
  }

  registerCallbacksMessage(callback: (...args: any[]) => any): void {
    this._registerCallbacksMessage = callback;
  }
}

export default new RoktProxy();
