import { Margins } from '@shared/types/commService';

const DEFAULT_CONTAINER_ID = 'rokt-placeholder';
const S3_PUBLIC_DOMAIN_NAME = 'https://spon.ticketmaster.net/spons-parkwhiz-sdk';
const IFRAME_ID = 'rokt-iframe';
const IFRAME_STYLE = 'width: 100%; border: none;';

const MARGIN_LIST: Margins[] = [
  {
    pageIdentifier: 'rco_upsells',
    components: [],
    className: '',
  },
  {
    pageIdentifier: 'co2_upsells',
    components: [],
    className: 'negative-margin-body',
  },
];

export { DEFAULT_CONTAINER_ID, IFRAME_ID, IFRAME_STYLE, MARGIN_LIST, S3_PUBLIC_DOMAIN_NAME };
