export type SDKProps = {
  [index: string]: boolean | string | ((...args: any[]) => any) | SDKAttributes | undefined;
  attributes?: SDKAttributes;
  component?: string;
  onCartUpdated?: (...args: any[]) => any;
  onFailure?: (...args: any[]) => any;
  onLoaded?: (...args: any[]) => any;
  onUpdateItem?: (...args: any[]) => any;
  onFanaticsDeliveryUpdate?: (...args: any[]) => any;
  onReadMoreCallback?: (...args: any[]) => any;
  onUpdatePresent?: boolean;
  onFanaticsDeliveryUpdatePresent?: boolean;
  onReadMoreCallbackPresent?: boolean;
  pageIdentifier?: string;
  roktTagId?: string;
  sponsId?: string;
  mode?: string;
  noDeviceId?: boolean;
};

export enum SDKMode {
  TRANSACTIONAL = 'transactional',
  EPHEMERAL = 'ephemeral',
}

type SDKAttributes = {
  country?: string;
  deviceplatform?: string;
  eventId?: string;
  locale?: string;
  venueName?: string;
};

export type initResponse = {
  errorMessage: string;
  loaded: boolean;
};

export type widgetCallbacks = {
  onFailure?: (...args: any[]) => any;
  onUpdateCartItem?: (...args: any[]) => any;
};
